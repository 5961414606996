import { FC } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import { PhoneAvailability } from 'components/phone-availibility/PhoneAvailability'
import { Language } from 'types/language'
import { routes } from 'utils/routes'
import { socials } from 'constants/constants'
import { Email, Facebook, Instagram, Logo } from 'assets/svg'
import StayInformed from './stay-informed/StayInformed'
import styles from './Footer.module.scss'
import { FooterProps as Props } from 'components/footer/types'
import classNames from 'classnames'
import { useGetActiveVacanciesQuery } from 'store/api/cmsApi'
import { useStoreSelector } from 'hooks/store'
import { SourceType } from 'types/marketing-params'

const Footer: FC<Props> = ({ extraSpaceFooter, withColumns = true }) => {
  // Redux
  const { sourceType } = useStoreSelector((store) => store.marketing)
  const { data: activeVacancies } = useGetActiveVacanciesQuery()

  // Next router
  const { locale: language } = useRouter()

  // i18n
  const { t } = useTranslation(['footer', 'common', 'header', 'lookingForHelp'])

  return (
    <footer className={classNames(styles.footer, { [styles['extra-space']]: extraSpaceFooter })}>
      <div className={styles.top}>
        <div className="container">
          <div className={styles['logo-row']}>
            <Link href={routes(language).home} aria-label={language === Language.FRENCH ? 'accueil' : 'home'}>
              <Logo className={styles.logo} />
            </Link>
            <div className={styles['socials-container']}>
              <a href={socials.facebook} aria-label="Facebook" target="_blank" rel="noopener noreferrer" className={styles['icon-link']}>
                <Facebook viewBox="0 0 512 512" className={styles.icon} />
              </a>
              <a href={socials.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer" className={styles['icon-link']}>
                <Instagram viewBox="0 0 512 512" className={styles.icon} />
              </a>
              <a href={socials.email} aria-label="Email" target="_blank" rel="noopener noreferrer" className={styles['icon-link']}>
                <Email viewBox="0 0 512 512" className={styles.icon} />
              </a>
            </div>
          </div>
          {withColumns && (
            <div className={styles.columns}>
              {/* FIRST COLUMN */}
              <div>
                <div className={styles['menu-section']}>
                  <h3 className={styles['list-title']}>{t('lookingForHelp:needHelp', 'Hulp nodig?')}</h3>
                  <ul>
                    <li>
                      <a href="tel:028993300" className="phone-link">
                        {t('lookingForHelp:callNumber', 'Bel 02 899 33 00')}
                      </a>
                    </li>
                    <li>
                      <PhoneAvailability />
                    </li>
                  </ul>
                </div>
                <div className={styles['menu-section']}>
                  <h3 className={styles['list-title']}>{t('ourAddress', 'Ons adres')}</h3>
                  <ul>
                    <li>{parse(t('address', 'Bolt, Botanic Tower<br />Sint-Lazaruslaan 4-10<br />1210 Brussel'))}</li>
                  </ul>
                </div>
              </div>
              {/* SECOND COLUMN */}
              <div>
                <div className={styles['menu-section']}>
                  <h3 className={styles['list-title']}>{t('becomeProducer', 'Word energie-opwekker')}</h3>
                  <ul>
                    <li>
                      <Link href={routes(language).miniGenerators}>{t('header:miniProducer', 'Mini-opwekker')}</Link>
                    </li>
                    <li>
                      <Link href={`${routes(language).becomeProducer}`}>{t('header:producer', 'Opwekker')}</Link>
                    </li>
                  </ul>
                </div>

                <div className={styles['menu-section']}>
                  <h3 className={styles['list-title']}>{t('usefulLinks', 'Handige links')}</h3>
                  <ul>
                    <li>
                      <Link href={routes(language).referral}>{t('referral', 'Overtuig een vriend')}</Link>
                    </li>
                    <li>
                      <Link href={routes(language).priceList}>{t('pricelists', 'Tariefkaarten')}</Link>
                    </li>
                    <li className={styles.vacancies}>
                      <Link href={routes(language).careers}>{t('careers', 'Vacatures')}</Link>
                      {!!activeVacancies?.[language] && (
                        <data className={styles['vacancies-notification']} value={activeVacancies[language]}>
                          {activeVacancies[language]}
                        </data>
                      )}
                    </li>
                  </ul>
                </div>
                <div className={styles['menu-section']}>
                  <ul>
                    {sourceType === SourceType.PULL && (
                      <li>
                        <Link href={routes(language).boltGo}>Bolt Go</Link>
                      </li>
                    )}
                    <li>
                      <a
                        href={language === Language.DUTCH ? 'https://bolt.prezly.com' : 'https://bolt.prezly.com/fr'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('press', 'Press')}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://files.boltenergie.be/energieovernamedocument/${
                          language === Language.FRENCH ? 'document-de-reprise-des-energies' : 'energieovernamedocument'
                        }.pdf`}
                        download={true}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('energyTransitionDocument', 'Energieovernamedocument')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* THIRD COLUMN */}
              <div>
                <div className={styles['menu-section']}>
                  <h3 className={styles['list-title']}>{t('common:stayInformedTitle', 'Blijf op de hoogte')}</h3>
                  <p suppressHydrationWarning className="mb-500">
                    {t(
                      'common:stayInformedSubtitle',
                      'Schrijf je in voor onze nieuwsbrief en ontvang het laatste nieuws over Bolt in je mailbox!'
                    )}
                  </p>
                  <StayInformed />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <div className={styles.bottom}>
          <span>
            Copyright &copy; {new Date().getFullYear()} Bolt Energie {language === Language.DUTCH ? 'bv' : 'SRL'} – BE 0726.633.730
          </span>
          <ul className={styles['bottom-menu']}>
            <li>
              <Link href={routes(language).terms} legacyBehavior>
                {t('termsAndConditions', 'Algemene voorwaarden')}
              </Link>
            </li>
            <li>
              <Link href={routes(language).privacy} legacyBehavior>
                {t('disclaimerPrivacyPolicy', 'Disclaimer en privacy policy')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
